<template>
	<div class="partners">
		<h2>{{ $t(`title.partners`) }}</h2>
		<div>
			<Gallery
				:slides-per-view="2"
				:space-between="0"
				:breakpoints="{
					640: {
						slidesPerView: 4,
						spaceBetween: 0,
					},
				}"
				:slides="slides"
				type="partner"
			/>
		</div>
	</div>
</template>

<script>
import Gallery from '../components/Gallery.vue';
import images from '../images.js';
export default {
	components: {
		Gallery,
	},

	data: () => ({
		slides: [
			{
				type: 'presenter',
				url: images['cmh_logo.png'],
				link: 'https://www.clipmyhorse.tv/hu_HU/'
			},

			{
				type: 'presenter',
				url: images['equishop.svg'],
				link: 'https://www.equishop.hu/hu'
			},
			{
				type: 'presenter',
				url: images['freejump.svg'],
				link: 'https://www.freejumpsystem.com/en/'
			},
			{
				type: 'presenter',
				url: images['inter_horse_truck.svg'],
				link: 'http://www.interhorsetruck.eu/hun/'
			},
			{
				type: 'presenter',
				url: images['antares.svg'],
				link: 'https://antares-sellier.com/en/'
			},
		],
	}),
};
</script>

<style lang="scss" scoped>
.partners {
	margin-top: 10vw;

	h2 {
		margin: 11vw 0 6vw 0;
		text-align: center;
		text-transform: uppercase;
	}

	> div {
		height: 40vw;
		padding-top: 70px;
		position: relative;
	}
}
@media (max-width: 1024px) {
	.partners {
		h2 {
			font-size: 2.5rem;
		}
	}
}
@media (max-width: 640px) {
	.partners {
		h2 {
			margin: 0vw 0 6vw 0;
			font-size: 2rem;
		}

		> div {
			height: 80vw;
		}
	}
}

@media (min-width: 1920px) {
	.partners {
		h2 {
			margin: 10vw 0 5vw 0;
		}

		> div {
			height: 35vw;
			max-height: 700px;
		}
	}
}
</style>
