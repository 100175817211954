<template>
	<swiper :class="type" :slides-per-view="'auto'" :space-between="40" class="mySwiper" :navigation="true">
		<swiper-slide v-for="(slide, index) in slides" :key="index">
			<img v-if="slide.type === 'image'" :src="slide.url" />
			<a v-if="slide.type === 'image_link'" target="_blank" :href="slide.link">
				<img :src="slide.url" />
			</a>
			<a v-else-if="slide.type === 'presenter'" :href="slide.link" target="_blank">
				<img :src="slide.url" loading="lazy" decoding="auto" />
			</a>
			<div v-else class="gallery-container" style="width: 100%" @click="popUpIsOpen = !popUpIsOpen">
				<img style="width: 100%" :src="`https://img.youtube.com/vi/${slide.url}/maxresdefault.jpg`" alt="" />
				<svg
					style="z-index: 9999; margin: auto; top: 0; left: 0; right: 0; bottom: 0; position: absolute"
					width="71"
					height="71"
					viewBox="0 0 71 71"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="35.3313" cy="35.3313" r="35.3313" fill="#E8E1D6" />
					<path d="M45.1052 34.9562L29.8827 43.745L29.8827 26.1675L45.1052 34.9562Z" fill="#1B2B3D" />
				</svg>
			</div>
			<Popup :url="slide.url" @close-popup="popUpIsOpen = !popUpIsOpen" v-if="popUpIsOpen"> </Popup>
		</swiper-slide>
	</swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';

import Popup from '../components/Popup.vue';

import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);

export default {
	components: {
		Swiper,
		SwiperSlide,
		Popup,
	},
	name: 'Gallery',
	props: {
		type: {
			type: String,
			default: '',
		},
		slides: {
			type: Array,
			default: function () {
				return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			},
		},
	},
	data: () => {
		return {
			popUpIsOpen: false,
		};
	},
};
</script>

<style lang="scss">
$text_color: #e8e1d6;

.swiper-container {
	height: 100%;
	display: flex;
	align-items: flex-end;
	position: initial;
}

.swiper-slide {
	font-size: 18px;
	display: flex;
	align-self: flex-end;

	img {
		height: 100%;
		object-fit: cover;
		object-position: top;
	}
	.gallery-container {
		iframe {
			width: 100%;
			height: auto;
			background: #1b2b3d;
		}

		span {
			width: 700px;
			display: block;
		}
	}
}

.swiper-slide {
	width: auto;
}

.swiper-button-prev,
.swiper-button-next {
	margin: 0;
	bottom: -16px;
	top: auto;
	width: 45px;
	left: 0;
	height: 60px;
	z-index: 0;

	&::after {
		width: inherit;
		color: transparent;
		background-repeat: no-repeat;
		transition: ease-in 0.2s;
		height: 100%;
	}

	&:hover {
		transition: ease-in 0.2s;
	}
}

.swiper-button-prev {
	&::after {
		background-image: url('.././assets/img/left01.svg');
		background-position: right;
	}

	&:hover {
		&::after {
			background-position: left;
		}
	}
}

.swiper-button-next {
	left: 90px;

	&::after {
		background-image: url('.././assets/img/right-01-01.svg');
		background-position: left;
	}

	&:hover {
		&::after {
			background-position: right;
		}
	}
}

.actuality {
	.swiper-slide {
		img {
			width: 25vw;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		bottom: auto;
		top: 0;
	}

	.swiper-button-prev {
		right: 90px;
		left: auto;
	}

	.swiper-button-next {
		right: 0px;
		left: auto;
	}
}

.multi {
	&.swiper-container {
		height: 60vw !important;
	}
	.swiper-wrapper {
		height: 100%;
	}

	.swiper-button-prev,
	.swiper-button-next {
		bottom: 32px;
		top: auto;
		z-index: 6;
	}

	.swiper-button-prev {
		left: 3vw;
	}

	.swiper-button-next {
		left: 150px;
	}
	.swiper-slide {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgb(27, 43, 61, 0.5);
			pointer-events: none;
		}
	}
}

.partner {
	&.swiper-container {
		&::before {
			content: '';
			display: block;
			height: 100%;
			width: 2px;
			background-color: #1b2b3d;
			position: absolute;
			z-index: 6;
		}
		&::after {
			content: '';
			display: block;
			height: 100%;
			width: 2px;
			background-color: #1b2b3d;
			position: absolute;
			z-index: 6;
			right: 0;
		}
	}

	.swiper-slide {
		border-left: 1px solid $text_color;
		border-top: 1px solid $text_color;
		border-bottom: 1px solid $text_color;

		a {
			display: flex;
			align-self: center;
			width: 100%;
			height: fit-content;
		}

		img {
			object-fit: contain;
			width: 40%;
			height: auto;
			margin: auto;
			max-height: 160px;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		bottom: auto;
		top: 0;
	}

	.swiper-button-prev {
		right: 90px;
		left: auto;
	}

	.swiper-button-next {
		right: 0px;
		left: auto;
	}
}
:root {
	--swiper-navigation-size: 63px !important;
}
@media (max-width: 1024px) {
	.multi {
		.swiper-slide img {
			height: 90%;
		}

		.swiper-button-prev,
		.swiper-button-next {
			margin: 0;
			bottom: 0;
			width: 40px;
			left: auto;
			top: unset;

			&::after {
				transition: none;
			}
			&:hover {
				transition: none;
			}
		}

		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			right: 77vw;
		}
	}

	:root {
		--swiper-navigation-size: 40px !important;
	}

	.actuality {
		.swiper-slide {
			img {
				width: 65vw;
			}
		}
	}
}

@media (max-width: 640px) {
	.swiper-button-prev,
	.swiper-button-next {
		margin: 0;
		top: unset;
		width: 45px;
		left: auto;
	}

	.swiper-button-prev {
		left: 0;
	}

	.swiper-button-next {
		right: 0;
	}

	.partner {
		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			right: 0;
		}
	}

	.actuality {
		.swiper-slide {
			img {
				width: 92vw;
			}
		}

		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			right: 0;
		}
	}

	.multi {
		align-items: unset;
		&.swiper-container {
			height: 75vw !important;
		}
		.swiper-slide img {
			width: 100%;
			height: 63vw !important;
		}
		.swiper-button-prev,
		.swiper-button-next {
			margin: 0;
			bottom: 0;
			width: 40px;
			top: unset;
			left: unset;
			right: 0;
		}

		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			left: 79vw;
		}
	}
}
</style>
