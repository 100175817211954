<template>
	<div class="container">
		<div class="blue-veil"></div>
		<Popup class="popup" :url="selectedVideo.url" @close-popup="popUpIsOpen = !popUpIsOpen" v-if="popUpIsOpen"> </Popup>
		<div class="some">
			<div class="title-container">
				<div
					:class="{
						active: videos.indexOf(selectedVideo) === index,
						first: videos.indexOf(videos.find(n => n !== selectedVideo)) === index,
					}"
					@click="selectedVideo = video"
					v-for="(video, index) in videos"
					:key="'video_' + index"
				>
					<h2 class="title">
						{{ $t(`video_gallery.title.${video.title}`) }}
					</h2>
				</div>
			</div>
		</div>
		<Transition name="fade" mode="default">
			<div class="gallery-container" @click="popUpIsOpen = !popUpIsOpen" :key="`video_${selectedVideo.url}`">
			<div class="blue-veil-tablet"></div>
				<img :src="`https://img.youtube.com/vi/${selectedVideo.url}/maxresdefault.jpg`" alt="" />
				<svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="35.3313" cy="35.3313" r="35.3313" fill="#E8E1D6" />
					<path d="M45.1052 34.9562L29.8827 43.745L29.8827 26.1675L45.1052 34.9562Z" fill="#1B2B3D" />
				</svg>
			</div>
		</Transition>

		<Transition name="fade" mode="default">
			<div class="descriptions" :key="`desc_${selectedVideo.url}`">
				<p>{{ $t(`video_gallery.desc.${selectedVideo.desc}`) }}</p>
			</div>
		</Transition>
		<div class="links" v-if="$route.name == 'Home'">
			<localized-link to="/info/referencia">
				<span class="spanHover">{{ $t(`link.reference`) }}</span>
				<span class="arrow"><img src=".././assets/img/arrow-right.svg" alt="" /></span
			></localized-link>
		</div>
	</div>
</template>

<script>
import Popup from '../components/Popup.vue';

export default {
	name: 'VideoGallery',
	props: { videos: Array },

	components: {
		Popup,
	},

	data() {
		return {
			popUpIsOpen: false,
			selectedVideo: this.videos[0],
		};
	},
};
</script>

<style lang="scss" scoped>
$text_color: #e8e1d6;
.container {
	position: relative;
	height: 83vh;

	.blue-veil,
	.blue-veil-tablet {
		background-color: rgba(27, 43, 61, 0.4);
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 1;
	}

	.blue-veil-tablet {
		display: none;
	}
	.swiper-container {
		z-index: 2;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	.title-container {
		display: flex;
		justify-content: space-between;
		position: absolute;
		z-index: 5;
		top: 3vw;
		width: 100%;
		padding: 0 3vw 0 3vw;

		div {
			flex: 0 0 30%;
			border-top: 1px solid rgb(232, 225, 214, 0.5);

			&:hover {
				border-top: 1px solid $text_color;
				h2 {
					color: $text_color;
				}
			}

			&.active {
				border-top: 1px solid $text_color;
				h2 {
					color: $text_color;
				}
			}
		}

		h2 {
			cursor: pointer;
			font-size: 1rem;
			margin-top: 12px;
			color: rgb(232, 225, 214, 0.5);
		}
	}

	.gallery-container {
		height: 100%;
		width: 100%;
		position: absolute;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		svg {
			z-index: 9;
			margin: auto;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			cursor: pointer;
		}
	}

	.links {
		bottom: 0;
		right: 2vw;
		padding: 0 0 2vw 0;
		position: absolute;
		width: fit-content;
		display: flex;
		justify-content: flex-end;
		z-index: 5;

		a {
			align-items: center;
			display: flex;
			justify-content: flex-start;
			width: 250px;
			transition: ease-in 0.2s;

			color: $text_color;
			.arrow {
				margin-left: 15px;
				transition: ease-in 0.2s;
			}

			&:hover .arrow {
				margin-left: 25px;
				transition: ease-in 0.2s;
			}
		}
	}

	.descriptions {
		top: unset;
		width: 100%;
		z-index: 2;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 0 0 2vw 0;

		a {
			top: unset;
			text-decoration: none;
		}

		p {
			padding-left: 3vw;
			width: 52%;
		}
	}
}

@media (max-width: 1024px) {
	.container {
		height: 62vh;
		min-height: 690px;

		.blue-veil {
			display: none;
		}

		.title-container {
			position: initial;
			padding: 0 0 2vw;
		}
		.title {
			width: auto;
			margin-bottom: 2vw;
		}

		.gallery-container {
			height: 65%;
			min-height: 450px;

			.blue-veil-tablet {
				display: block;
			}
		}
		.descriptions {
			display: flex;
			justify-content: flex-end;
			top: auto;
			bottom: 30px;

			p {
				width: 50%;
				padding-left: 0;
				font-size: 1rem;
			}

			a {
				margin: 0;
				text-decoration: none;
				text-align: center;
			}
		}

		.links {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			right: 0;
			padding: 0;

			a {
				display: block;
				transition: none;
				width: auto;
				height: auto;
				width: 50%; 
				.arrow {
					margin-left: 0;
					transition: none;
					padding-left: 15px;
				}

				&:hover .arrow {
					margin-left: 0;
					transition: none;
				}
			}
		}
	}
}

@media (max-width: 640px) {
	.container {
		display: flex;
		flex-direction: column;
		height: 88vh;
		min-height: auto;
		margin-bottom: 40px;

		.gallery-container {
			position: absolute;
			min-height: auto;
			top: 150px;
			max-height: 280px;
			height: 60%;
		}
		.links {
			display: flex;
			padding: 8px 0 10px 0;
			border-top: 1px solid $text_color;
			border-bottom: 1px solid $text_color;
			width: 100%;
			bottom: 0;

			a {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
		}

		.some {
			z-index: 9;
			.title-container {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				font-size: 1rem;
	
				div {
					width: 50%;
					min-width: 40vw;
					margin: 2vw;
					position: relative;
					color: $text_color;
					border-top: 1px solid $text_color;
	
					&:not(.active, .first) {
						padding-left: 30px;
						h2 {
							text-align: right;
							font-size: 0.7rem;
							color: $text_color;
						}
	
						&::before {
							content: '';
							display: block;
							height: 15px;
							width: 15px;
							background-image: url('../assets/img/arrow-mobile.svg');
							background-repeat: no-repeat;
							background-size: contain;
							position: absolute;
							top: 20px;
							left: 0;
						}
					}
	
					&.first {
						padding-right: 30px;
	
						&::before {
							content: '';
							display: block;
							height: 15px;
							width: 15px;
							background-image: url('../assets/img/arrow-mobile-sec.svg');
							background-repeat: no-repeat;
							background-size: contain;
							position: absolute;
							top: 20px;
							right: 0;
						}
						h2 {
							text-align: left;
							font-size: 0.7rem;
							color: $text_color;
						}
					}
	
					&.active {
						order: 3;
						margin: 0;
						width: 100%;
						min-width: 80vw;
						border: none;
	
						h2 {
							width: 100%;
							font-size: 1.5rem;
							line-height: 1.1;
						}
					}
				}
	
				h2 {
					font-size: 0.8rem;
				}
			}
		}

		.descriptions {
			display: flex;
			flex-direction: column;
			position: absolute;
			bottom: 57px;
			min-height: 90px;
			justify-content: flex-start;

			p {
				width: 100%;
				padding-left: 0;
				font-size: 1.1rem;
			}

			a {
				margin: 0;
				text-decoration: none;
				text-align: center;
			}
		}
	}
}

@media (max-width: 400px) {
	.container {
		height: 85vh;
		.gallery-container {
			top: 160px;
			max-height: 230px;
		}
	}
}

@media (min-width: 1920px) {
	.container {
		// .title-container {
		//   h2 {
		//     margin-top: 0;
		//   }
		// }
		.links a {
			height: 1vw;
		}
		.descriptions {
			p {
				width: 33vw;
			}
		}
	}
}
</style>
