<template>
	<div>
		<div class="contact-us">
			<div>
				<div>
					<h2>{{ $t(`contact.title`) }}</h2>
					<p>{{ $t(`contact.call`) }}</p>
					<localized-link to="/kapcsolat" class="link"
						>{{ $t(`contact.link`) }} <span class="arrow"><img src=".././assets/img/arrow-right.svg" alt="" /></span
					></localized-link>
				</div>
			</div>
			<div class="img-container">
				<img src="../assets/img/contact.jpg" alt="" />
			</div>
		</div>
	</div>
</template>

<script></script>

<style lang="scss" scoped>
$text_color: #e8e1d6;
.contact-us {
	display: flex;
	padding: 10vw 0 3vw 0;

	div {
		flex: 1;
	}

	h2,
	p {
		margin-right: 22vw;
		line-height: 1.3;
		width: 95%;
		font-size: 3rem;
	}

	.img-container {
		img {
			width: 100%;
			height: 690px;
			object-fit: cover;
		}
	}

	.link {
		display: flex;
		width: fit-content;
		margin-top: 2vw;

		&:hover {
			color: rgb(232, 225, 214, 0.7);
			//argin-right: 10px;
			transition: ease-in 0.2s;

			.arrow {
				margin-left: 20px;
				transition: ease-in 0.2s;
			}
		}
		.arrow {
			margin-left: 16px;
			transition: ease-in 0.2s;
		}
	}
}

@media (max-width: 1024px) {
	.contact-us {
		flex-direction: column;
		padding-bottom: 4vw;

		.links {
			:hover {
				margin-right: 0;
				transition: none;

				.arrow {
					margin-left: 0;
					transition: none;
				}
			}
			.arrow {
				margin-left: 0;
				transition: none;
				margin-left: 0;
			}
			&:hover .arrow {
				margin-left: 0;
				transition: none;
			}
			a {
				text-decoration: none;
				color: $text_color;
				transition: none;
				font-size: 1rem;
			}
			img {
				padding-left: 10px;
			}
		}

		h2 {
			margin: 0 50vw 0 0vw;
			font-size: 3rem;
			line-height: normal;
			width: 100%;
		}

		p {
			line-height: normal;
		}

		.contact-us-text {
			h2 {
				margin-left: 10vw;
				color: rgb(255, 255, 255);
			}
			a {
				color: rgb(50, 19, 19);
			}
		}
		.img-container {
			img {
				width: 100%;
				object-fit: cover;
				margin-top: 2.5vw;
			}
		}
	}
}

@media (max-width: 640px) {
	.contact-us {
		padding-bottom: 5vw;
		h2,
		p {
			width: 100%;
			font-size: 1.8rem;
			margin: 0;
		}

		.link {
			margin: 10vw 0;
		}
		.links {
			margin: 8vw 0 6vw 0;
			.contact-text {
				font-size: 1rem;
				margin-bottom: 2vw;
			}
		}
		.arrow-svg {
			img {
				width: 7vw;
			}
		}

		.img-container {
			img {
				margin-top: 5vw;
				height: 110vw;
			}
		}
	}
}
@media (min-width: 1920px) {
	.contact-us {
		padding: 6vw 0 3vw 0;
	}
}
</style>
