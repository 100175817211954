<template>
	<div>
		<div class="container">
			<h2>{{ title }}</h2>
			<p class="about-text">{{ description }}</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		description: String,
	},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.container {
	padding: 10vw 15vw 0 15vw;
	text-align: center;

	h2 {
		text-transform: uppercase;
		margin-bottom: 16px;
	}
}
@media (max-width: 1024px) {
	.container {
		padding: 10vw 3vw 0 3vw;

		h2 {
			font-size: 3rem;
			padding-bottom: 3vw;
		}
		.about-text {
			font-size: 1rem;
			width: 83%;
			margin: 0 auto;
		}
	}
}

@media (max-width: 640px) {
	.container {
		padding: 15vw 2vw 10vw 0vw;
		font-size: 1rem;
		h2 {
			padding-bottom: 5vw;
			font-size: 2rem;
		}
		.about-text {
			width: 92%;
		}
	}
}
</style>
