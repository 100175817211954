<template>
	<div>
		<div>
			<div class="contact_us">
				<h2>{{ $t(`title.contact`) }}</h2>
				<div class="main-div">
					<div class="left-container">
						<div class="phone-nr">
							<p>{{ $t(`details.phone`) }}</p>
							<a href="tel:00 36 20 398 8262">+36 20 398 8262</a>
							<a href="tel:00 36 70 378 9089">+36 70 378 9089</a>
							<a href="tel:+49 170 4133111">+49 170 413 3111</a>
						</div>
						<div class="address-container">
							<p class="address">{{ $t(`details.address_title`) }}</p>
							<p class="address2">{{ $t(`details.address`) }}</p>

							<div class="links2">
								<a
									href="https://www.google.com/maps/place/Baracska,+Sz%C3%A9chenyi+u.,+2471/@47.2834366,18.7665212,17z/data=!3m1!4b1!4m5!3m4!1s0x4741fd78dc83b4bb:0x62333059e56d7f5a!8m2!3d47.2834366!4d18.7687099"
									target="_blank"
								>
									<span class="spanHover">{{ $t(`link.place`) }}</span>
									<span class="arrow"><img src=".././assets/img/arrow-right.svg" alt="" /></span
								></a>
							</div>
						</div>
					</div>
					<div class="right-container">
						<form v-if="!sent" @submit.prevent="handleSubmit" action="" method="POST" autocomplete="off">
							<div>
								<label class="name" for="name">{{ $t(`details.name`) }}</label>
								<input type="text" name="name" class="name" v-model="name" :class="{ error: errors.name }" />
							</div>
							<p v-if="errors.name" class="error-message">
								{{ $t(`details.error_msg`) }}
							</p>

							<div class="email-address">
								<label for="email" class="email">Email</label>

								<input type="text" name="email" :class="{ error: errors.email }" class="emial" v-model="email" />
							</div>

							<p v-if="errors.email" class="error-message">
								{{ $t(`details.error_msg`) }}
							</p>

							<div class="phone">
								<label for="phone">{{ $t(`details.phone_number`) }}</label>

								<input type="tel" name="phone" v-model="phone" :class="{ error: errors.phone }" />
							</div>

							<p v-if="errors.phone" class="error-message">
								{{ $t(`details.error_msg`) }}
							</p>

							<div class="message-us">
								<label for="message" class="message"> {{ $t(`details.message`) }}</label>

								<textarea class="message" rows="10" :placeholder="$t(`details.message_text`)" v-model="text"></textarea>
							</div>
							<div class="send-btn">
								<button type="submit">
									<span class="spanHover">{{ $t(`link.send`) }}</span>
									<span class="arrow"><img src=".././assets/img/arrow-right.svg" alt="" /></span>
								</button>
							</div>
						</form>
						<div class="sent-message" v-else>
							{{ $t(`details.sent_message`) }}
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
import emailjs from '@emailjs/browser';

export default {
	components: {
		Footer,
	},

	data() {
		return {
			text: '',
			name: '',
			email: '',
			sent: false,
			phone: '',
			errors: {
				name: false,
				email: false,
				phone: false,
				text: false,
			},
		};
	},
	methods: {
		handleSubmit: function () {
			this.errors = {
				name: false,
				email: false,
				phone: false,
				text: false,
			};

			if (!this.name) {
				this.errors.name = true;
			}
			if (!this.email) {
				this.errors.email = true;
			} else if (!this.validEmail(this.email)) {
				this.errors.email = true;
			}

			if (!this.phone) {
				this.errors.phone = true;
			}

			if (!this.checkForErrors()) {
				emailjs
					.send(
            process.env.VUE_APP_EMAILJS_SERVICE_ID,
            process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
						{
							user_email: `${this.email}`,
							user_name: `${this.name}`,
							message: `név: ${this.name}, email: ${this.email}, telefon: ${this.phone}, üzenet: ${this.text}`,
						},
            process.env.VUE_APP_EMAILJS_TEMPLATE_ID
					)
					.then(
						function (response) {
							console.log('SUCCESS!', response.status, response.text);
						},
						function (error) {
							console.log('FAILED...', error);
						}
					);
				this.sent = true;
			}
		},
		validEmail: function (email) {
			var re =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		checkForErrors: function () {
			return Object.values(this.errors).filter(b => b).length > 0;
		},
	},
};
</script>

<style lang="scss" scoped>
$text_color: #e8e1d6;

::placeholder {
	opacity: 0.6;
}
.contact_us {
	margin: auto;
	max-width: 1920px;
	padding: 0 2vw 0 2vw;
	font-family: Galano;
	h2 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin-top: 10vw;
		margin-bottom: 10vw;
		font-size: 2.5rem;
		text-transform: uppercase;
	}

	.main-div {
		display: flex;
		width: 100%;
		border-top: 1px solid $text-color;
		min-height: 50vh;
		.right-container {
			width: 50%;
			padding-left: 2vw;

			.error-message {
				font-size: 0.7rem;
				color: #fd4d26;
			}
			label {
				font-size: 0.8rem;
			}
			input {
				border: none;
				width: 100%;
				border-bottom: 1px solid $text-color;
				background: #1b2b3d;
				outline: none;
				font-size: 1.3rem;
				color: $text-color;
				font-family: Galano, sans-serif;
				font-weight: 100;
				margin-top: 1.5vw;
				margin-bottom: 0.5vw;
				font-size: 1rem !important;

				&.error {
					border-bottom: 1px solid #fd4d26;
				}
			}

			.message-us {
				margin-top: 1.5vw;
				.message {
					width: 100%;
					background: #1b2b3d;
				}
				textarea {
					border: 1px solid $text-color;
					margin-top: 1vw;
					padding-top: 0.5vw;
					padding-left: 0.5vw;
					color: $text-color;
					font-family: Galano, sans-serif;
					padding: 1rem;
				}

				::placeholder {
					color: $text-color;
				}
			}

			::-webkit-input-placeholder {
				opacity: 1;
				-webkit-transition: opacity 0.5s;
				transition: opacity 0.5s;
			} /* Chrome <=56, Safari < 10 */

			*:focus::-webkit-input-placeholder {
				opacity: 0;
			} /* Chrome <=56, Safari < 10 */
			*:focus:-moz-placeholder {
				opacity: 0;
			} /* FF 4-18 */
			*:focus::-moz-placeholder {
				opacity: 0;
			} /* FF 19-50 */
			*:focus:-ms-input-placeholder {
				opacity: 0;
			} /* IE 10+ */
			*:focus::placeholder {
				opacity: 0;
			} /* Modern Browsers */

			input:focus::-webkit-input-placeholder {
				color: transparent;
			}
			input:focus:-moz-placeholder {
				color: transparent;
			} /* FF 4-18 */
			input:focus::-moz-placeholder {
				color: transparent;
			} /* FF 19+ */
			input:focus:-ms-input-placeholder {
				color: transparent;
			} /* IE 10+ */

			input:focus::-webkit-input-placeholder {
				opacity: 0;
			}

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px #1b2b3d inset !important;
			}

			input:-webkit-autofill,
			input:-webkit-autofill:focus {
				box-shadow: 0 0 0 1000px #1b2b3d inset;
				-webkit-text-fill-color: #ffffff;
			}

			.name,
			.email-address,
			.phone {
				width: 100%;
			}

			.send-btn {
				width: 100%;
				min-height: 6vh;
				display: flex;
				justify-content: center;
				margin-top: 19px;
				/* padding: 10px 0 10px 0; */

				button {
					align-items: center;
					width: 115px;
					color: $text-color;
					font-size: 0.7rem;
					background: transparent;
					border-left: none;
					border-right: none;
					border-bottom: none;
					border-top: 1px solid $text-color;
					display: flex;
					height: fit-content;
					padding-top: 10px;
					cursor: pointer;

					span {
						font-family: Galano, sans-serif;
					}

					:hover {
						color: hsl(37, 28%, 87%, 0.7) !important;
						transition: 0.5s ease-out;
					}
					.spanHover {
						margin-right: 10px;
					}
					&:hover .arrow {
						margin-left: 5px;
						transition: ease-in 0.2s;
					}
				}
			}
		}

		.left-container {
			width: 50%;
			border-right: 1px solid $text-color;
			a {
				text-decoration: none;
				color: $text-color;
				font-size: 1rem;
			}

			.phone-nr {
				display: flex;
				flex-direction: column;
				font-size: 1.8rem;
				padding-top: 2vw;

				p {
					font-size: 1.3rem;
					margin-bottom: 0.5vw;
				}

				a {
					font-size: 1.8rem;
					margin-bottom: 0.5vw;
				}
			}
			.address-container {
				margin-bottom: 50px;
				.address {
					padding-top: 2vw;
					font-size: 1.3rem;
					margin-top: 5vw;
					border-top: 1px solid $text-color;
				}
				p {
					font-size: 2rem;
					margin-bottom: 1vw;
					padding-right: 2vw;
				}
				a {
					text-decoration: none;
					font-size: 1.5rem;
				}

				.links2 {
					width: 100%;
					display: inline;
					a {
						display: flex;
						color: $text_color;
						font-size: 0.9rem;
						align-items: center;
						:hover {
							color: hsl(37, 28%, 87%, 0.7) !important;
							transition: 0.5s ease-out;
						}

						.arrow {
							margin-left: 15px;
							transition: ease-in 0.2s;
						}

						&:hover .arrow {
							margin-left: 25px;
							transition: ease-in 0.2s;
						}
					}
				}
			}
		}
		.right-container {
			margin-top: 1vw;

			.sent-message {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-top: 250px;
			}
		}
	}
}
@media (max-width: 1024px) {
	.contact_us {
		padding: 0;
		h2 {
			font-size: 2.5rem;
			margin-top: 28vw;
			margin-bottom: 12vw;
		}
		.main-div {
			height: auto;
			flex-direction: column-reverse;
			align-items: flex-start;
			border-top: none;
			margin-top: 15vw;

			.left-container {
				border-right: none;
				width: 100%;
				padding-left: 0;

				.phone-nr {
					border-top: 1px solid $text-color;
					padding-top: 4vw;
					margin-top: 5vw;
					margin-bottom: 9vw;

					p {
						margin-top: 2vw;
					}
				}

				.address-container {
					padding-bottom: 10vw;
					.address {
						padding-top: 4vw;
						font-size: 1.5rem;
					}
					a {
						font-size: 0.5rem;
					}
				}
			}
			.right-container {
				width: 100%;
				padding-left: 0;
				.phone {
					background: #1b2b3d;
				}
				.sent-message {
					margin-top: 2vw;
					margin-bottom: 20vw;
					font-size: 1.5rem;
				}
				.send-btn {
					padding: 0;
				}
			}
		}
		a {
			font-size: 1rem;
		}
	}
}

@media (max-width: 640px) {
	.contact_us {
		h2 {
			font-size: 2rem;
			margin-top: 10rem;
			margin-bottom: 4rem;
		}
		.main-div {
			height: auto;
			flex-direction: column-reverse;
			align-items: flex-start;
			border-top: none;
			margin-top: 15vw;

			.left-container {
				border-right: none;
				width: 100%;
				.address-container {
					margin: 6vw 0 12vw 0;
					.address-container p {
						font-size: 1rem;
					}
					a {
						font-size: 0.5rem;
					}
					.links2 {
						a {
							font-size: 0.9rem;
							margin-top: 4vw;
							.arrow {
								img {
									width: 40px;
								}
							}
						}
					}
					.address {
						padding-top: 6vw;
					}
				}
				.phone-nr {
					padding-top: 6vw;
					margin-top: 6vw;
					padding-bottom: 8vw;
				}
				.address2 {
					line-height: 1.2;
					margin-top: 2vw;
				}
			}
			.right-container {
				.name,
				.email-address,
				.phone {
					margin-top: 1.5vw;
				}
				width: 100%;
				.message-us {
					margin-top: 8vw;
					textarea {
						margin-top: 2vw;
					}
				}
				.sent-message {
					text-align: center;
					font-size: 1.4rem;
					text-align: center;
					width: 95%;
					margin-top: 10vw;
					margin-bottom: 30vw;
				}
				.send-btn {
					padding: 0;

					button {
						padding-top: 12px;
					}
				}
			}
		}
		a {
			font-size: 1rem;
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 60px #fafafa inset !important;
		background-color: #fafafa !important;
		background-clip: content-box !important;
	}

	input:-webkit-autofill {
		-webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
		-webkit-animation-name: autofill;
		-webkit-animation-fill-mode: both;
	}
}

@media (min-width: 1920px) {
	.contact_us {
		h2 {
			margin-top: 6vw;
			margin-bottom: 6vw;
			overflow: hidden;
		}
	}
}
</style>
