<template>
	<div>
		<div class="container">
			<div class="about">
				<h2>{{ $t(`title.riding`) }}</h2>
				<p>
					{{ $t(`description.riding.lead_1`) }}
				</p>
			</div>

			<div class="bg-img">
				<img src=".././assets/img/lovarda-header.jpg" alt="" />
			</div>

			<div class="section-container">
				<div class="right-container">
					<h2>
						{{ $t(`description.riding.owner.szabolcs`) }}
					</h2>
				</div>
				<div class="left-container">
					<div>
						<p class="new-des">
							{{ $t(`description.riding.lead_1`) }}
						</p>
					</div>
					<div class="story-text-last">
						<p>{{ $t(`description.riding.content_1`) }}</p>
						<p>{{ $t(`description.riding.content_2`) }}</p>
					</div>
					<div class="img-container">
						<img class="img" src="../assets/img/section1.jpg" alt="" />
					</div>
				</div>
			</div>

			<div class="section-container">
				<div class="right-container">
					<div class="name">
						<h2 class="n">
							{{ $t(`description.riding.owner.balazs`) }}
						</h2>
					</div>
				</div>
				<div class="left-container">
					<div>
						<p class="story-text-first">
							{{ $t(`description.riding.lead_2`) }}
						</p>
					</div>
					<div class="story-text-last">
						<p class="mobile-text">
							{{ $t(`description.riding.content_3`) }}
						</p>
					</div>
					<div class="img-container">
						<img class="img" src="../assets/img/section2.jpg" alt="" />
					</div>
				</div>
			</div>
			<h3>
				{{ $t(`description.riding.lead_3`) }}
			</h3>
			<div class="ridinghall-gallery">
				<Gallery
					:slides="slides"
					type="riding"
					arrow-position="arrow-bottom"
					:slides-per-view="1"
					:breakpoints="{
						640: {
							slidesPerView: 'auto',
							spaceBetween: 40,
						},
					}"
					:space-between="10"
				/>
			</div>

			<div class="contact">
				<div class="links">
					<localized-link to="/kapcsolat">
						<span class="spanHover">{{ $t(`details.contact_us_link`) }}</span>
						<span class="arrow"><img src=".././assets/img/arrow-right.svg" alt="" /></span
					></localized-link>
				</div>
			</div>
			<Footer />
		</div>
	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
import Gallery from '../components/Gallery.vue';

export default {
	components: {
		Footer,
		Gallery,
	},

	data: () => ({
		slides: [
			{
				type: 'image',
				url: '/img/riding6.jpg',
			},
			{
				type: 'image',
				url: '/img/riding3.jpg',
			},
			{
				type: 'image',
				url: '/img/riding7.jpg',
			},
			{
				type: 'image',
				url: '/img/riding1.jpg',
			},
			{
				type: 'image',
				url: '/img/riding9.jpg',
			},
			{
				type: 'image',
				url: '/img/riding14.jpg',
			},
			{
				type: 'image',
				url: '/img/riding15.jpg',
			},
			{
				type: 'image',
				url: '/img/riding16.jpg',
			},
			{
				type: 'image',
				url: '/img/riding17.jpg',
			},
			{
				type: 'image',
				url: '/img/riding18.jpg',
			},
			{
				type: 'image',
				url: '/img/riding19.jpg',
			},
			{
				type: 'image',
				url: '/img/riding20.jpg',
			},
			{
				type: 'image',
				url: '/img/riding21.jpg',
			},
			{
				type: 'image',
				url: '/img/riding22.jpg',
			},
			{
				type: 'image',
				url: '/img/riding23.jpg',
			},
			{
				type: 'image',
				url: '/img/riding24.jpg',
			},
			{
				type: 'image',
				url: '/img/riding25.jpg',
			},
			{
				type: 'image',
				url: '/img/riding26.jpg',
			},
			{
				type: 'image',
				url: '/img/riding27.jpg',
			},
			{
				type: 'image',
				url: '/img/riding31.jpg',
			},
			{
				type: 'image',
				url: '/img/riding28.jpg',
			},
			{
				type: 'image',
				url: '/img/riding29.jpg',
			},
			{
				type: 'image',
				url: '/img/riding30.jpg',
			},
		],
		responsive: {
			mobile: {
				itemsToShow: 1,
			},
			tablet: {
				itemsToShow: 1.5,
			},
			desktop: {
				itemsToShow: 3,
			},
		},
	}),
};
</script>

<style lang="scss" scoped>
$text_color: #e8e1d6;
.container {
	padding: 0 2vw 0 2vw;
	margin: auto;
	max-width: 1920px;
	text-align: center;

	h2 {
		font-size: 3rem;
		line-height: 1.2;
		padding: 0;
		margin: 0;
	}

	h3 {
		text-align: left;
		font-size: 3rem;
		padding-top: 3vw;
		width: 70%;
		margin-bottom: 60px;
	}

	p {
		margin-bottom: 2vw;
	}

	.ridinghall-gallery {
		height: 46vw;
		padding-bottom: 70px;
		margin-bottom: 4vw;
		position: relative;
	}
	.about {
		padding: 4vw 15vw 0 15vw;
		text-align: center;

		h2 {
			text-transform: uppercase;
			line-height: normal;
		}
		p {
			margin: auto;
			margin-bottom: 7vw;
		}
	}
	.bg-img {
		img {
			width: 100%;
			border-top: 1px solid $text-color;
			padding-top: 2.1vw;
			padding-bottom: 2.1vw;
			border-bottom: 1px solid $text-color;
		}
	}

	.section-container {
		text-align: left;
		display: flex;
		padding: 3vw 0 3vw 0;
		justify-content: space-between;
		border-bottom: 1px solid $text-color;

		.right-container {
			width: 28%;
		}

		.left-container {
			width: 66%;

			div:first-child {
				padding-right: 3vw;
			}
			.story-text-first {
				font-size: 1.5rem;
			}
			.story-text-last {
				display: flex;
				justify-content: space-between;
				text-align: left;
				padding-right: 5vw;
				margin-bottom: 1vw;

				p:first-child {
					width: 52%;
				}

				p:last-child {
					width: 41%;
				}
			}
			.new-des {
				font-size: 1.8rem;
			}
			.mobile-text {
				width: 420px;
			}
			.img-container {
				position: relative;
				height: 30vw;
				width: 100%;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-position: center;
					object-fit: cover;
				}
			}
		}
	}
	.contact {
		padding-bottom: 8vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 0.8rem;

		.links {
			border-right: none;
			width: 100%;
			display: flex;
			justify-content: center;

			a {
				align-items: flex-end;
				display: flex;
				color: $text_color;
				width: fit-content;

				.spanHover {
					margin-right: 10px;
				}

				.arrow {
					position: relative;
					align-items: center;
					width: 56px;
					height: 16px;

					img {
						transition: all 0.2s ease-in;
						position: absolute;
						left: 0;
						bottom: 0;
						top: 0;
						margin: auto;
					}
				}
				&:hover {
					color: hsl(37, 28%, 87%, 0.7);

					.arrow {
						img {
							transform: translate(20%, 0);
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	.container {
		padding: 0 4vw 0 4vw;
		.ridinghall-gallery {
			height: 52vw;
			padding-bottom: 55px;
		}
		.contact {
			padding: 0 0 10vw;
		}
		.bg-img {
			img {
				padding-top: 4.1vw;
				padding-bottom: 4.1vw;
			}
		}
		p {
			width: 60%;
		}
		.about {
			margin: 10vw 0 13vw 0;
			padding: 0;
		}
		.about-text {
			margin: auto;
			width: 91%;
		}
		h3 {
			width: 100%;
			font-size: 3rem;
			line-height: 1.2;
			padding-bottom: 3vw;
		}

		.section-container {
			display: block;
			border-bottom: none;
			padding: 3vw 0 1vw 0;
			.right-container {
				width: 50%;
				padding-bottom: 3vw;
				h2 {
					font-size: 2rem;
				}
			}
			.left-container {
				width: 100%;

				.story-text-last {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					p:first-child {
						width: 69%;
						font-size: 1rem;
					}

					p:last-child {
						width: 69%;
						font-size: 1rem;
					}
				}

				.story-text-first {
					width: 100%;
				}

				.img-container {
					position: static;
					height: auto;
					overflow: visible;
					img {
						position: static;
						-webkit-transform: none;
						transform: none;
						width: 100%;
						margin-top: 3vw;
						padding-bottom: 4.3vw;
					}
				}
				.new-des {
					width: 100%;
					font-size: 1.7rem;
					line-height: 1.4;
					padding-bottom: 2.5vw;
				}
			}
		}
		.container p {
			width: 60%;
		}
		.contact {
			.links {
				a {
					width: auto;

					.arrow {
						transition: none;
						margin-left: 0;
						padding-left: 10px;
					}

					&:hover .arrow {
						transition: none;
						margin-left: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.container {
		padding: 0;

		.about {
			margin-top: 20vw;
		}
	}
}

@media (max-width: 640px) {
	.container {
		padding: 0;

		.ridinghall-gallery {
			height: 76vw;
		}
		.about {
			margin: 25vw 0 20vw 0;

			p {
				width: 100%;
				margin-bottom: 0;
			}
		}

		h2 {
			font-size: 2rem;
			margin-bottom: 5vw;
		}
		n {
			margin-top: -1vw;
		}
		.contact {
			padding: 5vw 0 15vw;
		}

		.bg-img {
			img {
				padding-top: 8vw;
				padding-bottom: 8vw;
				margin-bottom: 0;
			}
		}
		.about-text {
			margin: auto;
			padding: 0;
		}

		.section-container {
			display: block;
			border-bottom: none;

			.left-container {
				.img-container {
					img {
						margin-top: 6vw;
						padding-bottom: 8vw;
						height: 250px;
						object-fit: cover;
					}
				}
				.story-text-last {
					line-height: 1.6;
					width: auto;
					p:first-child {
						width: 100%;
						margin-bottom: 10vw;
					}

					p:last-child {
						width: 100%;
					}
				}
				.new-des {
					font-size: 1rem;
					margin-bottom: 7vw;
					line-height: 1.6;
				}
				.story-text-first {
					font-size: 1rem;
					width: 100%;
					margin-bottom: 10vw;
				}
			}
			.right-container {
				width: 80%;
				padding-bottom: 2vw;

				h2 {
					line-height: 1.2;
					margin: 0;
					width: 110%;
					margin-top: 6vw;
					padding-bottom: 7vw;
				}
			}
		}
		h3 {
			font-size: 1.7rem;
			width: 100%;
			padding-top: 6vw;
			margin-bottom: 7vw;
		}
	}
}

@media (min-width: 1920px) {
	.container {
		.ridinghall-gallery {
			height: 30vw;
		}
	}
}
</style>
