<template>
	<div class="popup">
		<div class="popup-inner">
			<iframe
				width="640"
				height="360"
				:src="`https://www.youtube.com/embed/${url}?autoplay=1&controls=0&modestbranding&rel=0`"
				allowfullscreen
				frameborder="0"
				allow="autoplay;"
			></iframe>
			<button @click="$emit('close-popup')">
				<svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 34.0649L34 1.00004" stroke="#E8E1D6" stroke-linecap="square" stroke-linejoin="round" />
					<path d="M1 1.93518L34 35.0001" stroke="#E8E1D6" stroke-linecap="square" stroke-linejoin="round" />
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		url: {
			type: String,
			default: '',
		},
	},
	mounted() {
		this.document.getElementsByTagName('body')[0].classList.add('hiddenscroll');
	},
	unmounted() {
		this.document.getElementsByTagName('body')[0].classList.remove('hiddenscroll');
	},
};
</script>

<style lang="scss" scoped>
$text_color: #e8e1d6;
.popup {
	position: fixed;
	width: 100%;
	height: 100%;
	bottom: 0;
	right: 0;
	z-index: 99999;

	.popup-inner {
		background: rgba(6, 2, 8, 0.514);
		z-index: 999999;
		width: 100%;
		height: 100%;
		z-index: 99999;
		iframe {
			height: 100%;
			width: 100%;
		}
		button {
			background: #02000c;
			color: $text_color;
			font-size: 3rem;
			z-index: 999;
			width: 70px;
			border: none;
			cursor: pointer;
			display: block;
			position: absolute;
			right: 0;
			top: 0;

			svg {
				height: 50%;
				width: 50%;
			}
		}
	}
}

@media (max-width: 640px) {
	.popup {
		.popup-inner {
			button {
				top: 44px;
			}
		}
	}
}
</style>
