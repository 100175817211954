<template>
	<div class="not_found_container">
		<div class="err">
			<div class="line_top"></div>
			<h2>404</h2>
			<h3>{{ $t(`404.not_found`) }}</h3>
			<div class="line_btm"></div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
export default {
	name: 'NotFound',
	components: {
		Footer,
	},
};
</script>

<style lang="scss" scoped>
$text_color: #e8e1d6;
.not_found_container {
	padding: 0 2vw 0 2vw;
	margin: auto;
	max-width: 1920px;
}
.err {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 90vh;
	position: relative;

	.line_top {
		border-left: 1px solid $text_color;
		position: absolute;
		width: 1px;
		height: 30vh;
		top: 100px;
		left: 0;
		right: 0;
		margin: auto;
	}
	.line_btm {
		border-left: 1px solid $text_color;
		height: 16vw;
		position: absolute;
		left: 0;
		margin: auto;
		width: 1px;
		right: 0;
		bottom: 100px;
		height: 30vh;
	}
	h2 {
		padding-top: 4vw;
	}
	h3 {
		padding-bottom: 4vw;
	}
}
@media (max-width: 1024px) {
	.not_found_container {
		.err {
			.line_top {
				top: 160px;
				height: 26vh;
			}
			.line_btm {
				bottom: 160px;
				height: 26vh;
			}
		}
	}
}

@media (max-width: 640px) {
	.not_found_container {
		.err {
			.line_top {
				top: 75px;
				height: 23vh;
			}
			.line_btm {
				bottom: 75px;
				height: 23vh;
			}
			h3 {
				width: 70%;
				line-height: 1.1;
			}
		}
	}
}
</style>
