const context = require.context('@/assets/img/', false, /\.(png|jpe?g|svg)$/);
const images = importAll(context);

function importAll(r) {
	let images = {};
	r.keys().map((item) => {
		images[item.replace('./', '')] = r(item);
	});
	return images;
}

export default images;
