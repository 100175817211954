const galleries = {
	about: {
		szabolcs: {
			slides: [
				{
					type: 'image',
					url: '/img/szabolcs-gallery8.jpg',
				},

				{
					type: 'image',
					url: '/img/szabolcs-gallery14.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery5.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery4.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery3.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery16.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery2.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery10.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery6.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery11.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery1.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery13.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery9.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery12.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery15.jpg',
				},
				{
					type: 'image',
					url: '/img/szabolcs-gallery17.jpg',
				},
			],
		},
		balazs: {
			slides: [
				{
					type: 'image',
					url: '/img/balazs-gallery1.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery2.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery3.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery4.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery5.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery6.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery7.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery9.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery10.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery12.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery13.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery14.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery15.jpg',
				},
				{
					type: 'image',
					url: '/img/balazs-gallery16.jpg',
				},
			],
		},
	},
	tenyesztes: [
		{
			title: 'teny1',
			url: '42343Fd2',
			desc: 'fafafafafafafafafafafafa',
		},
		{
			title: 'teny2',
			url: '42343Fd2',
			desc: 'fafafafafafafafafafafafa',
		},
		{
			title: 'teny3',
			url: '42343Fd2',
			desc: 'fafafafafafafafafafafafa',
		},
	],
	referencia: [
		{
			title: 'CSIO5* Budapest',

			url: 'aSoUt3nDVA8',
			desc: 'Szabolcs Krucsó and CHACCO BLUE II - CSIO5* BUDAPEST - LONGINES FEI JUMPING NATIONS CUPTM OF HUNGARY',
		},
		{
			title: 'Hungarian Championship 2020',
			url: 'LVLMEpcuVt4',
			desc: 'Hungarian Championship 2020, Nemzeti Lovarda, Szabolcs Krucsó and Lady Winner',
		},
		{
			title: 'CSI**-W Budapest Sportaréna,',
			url: 'U0OJp8-5t94',
			desc: 'CSI**-W Budapest Sportaréna, 2010 december 3-5 Puissance 208 cm, Szabolcs Krucsó and Regale Regatta',
		},
	],
};

export default galleries;
