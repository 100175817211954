<template>
	<footer>
		<div>
			<p>Copyright 2022 Krucso.com</p>
		</div>
		<div class="social">
			<ul>
				<li class="social-des">
					<a class="social-icons-mobile icon1" href="https://www.facebook.com/KrucsoLovarda/" target="_blank">
						<span>Facebook</span>
					</a>
				</li>
				<li class="social-des">
					<a class="social-icons-mobile icon2" href="https://www.instagram.com/krucsolovarda/?hl=hu" target="_blank">
						<span>Instagram</span>
					</a>
				</li>
				<li class="social-des">
					<a class="social-icons-mobile icon3" href="https://www.youtube.com/watch?v=jZonasZvE4c" target="_blank">
						<span>Youtube</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="scroll-to-top">
			<a href="#"
				>{{ $t(`footer.scroll`) }} <span class="arrow-up"><img src=".././assets/img/arrow-up.svg" alt="" /></span
			></a>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {};
	},
	methods: {
		moveUp() {
			window.scroll({ top: 0, behavior: 'smooth' });
		},
	},
};
</script>

<style lang="scss" scoped>
$text_color: #e8e1d6;

footer {
	align-items: center;
	display: flex;
	border-top: 1px solid $text_color;
	justify-content: space-between;
	padding: 40px 0 40px 0;

	div:first-child {
		width: 20%;
	}
	p {
		cursor: pointer;
		font-size: 0.7rem;
	}
	p:hover {
		color: rgb(232, 225, 214, 0.5);
		transition: 0.5s ease-out;
	}
	.social {
		width: 40%;
		ul {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			cursor: pointer;
			li {
				flex: 1;
				list-style: none;
				text-align: center;
			}
			:hover {
				color: rgb(232, 225, 214, 0.5);
				transition: 0.5s ease-out;
			}
		}
	}
	.scroll-to-top {
		width: 20%;
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
		font-size: 0.7rem;

		a {
			text-decoration: none;
			color: $text_color;
			&:hover {
				color: hsl(37, 28%, 87%, 0.7) !important;
				transition: 0.5s ease-out;
			}
		}

		.arrow-up {
			img {
				margin-left: 0.5vw;
			}
		}
	}
}

@media (max-width: 1024px) {
	footer {
		font-size: 0.6rem;
		.social {
			ul {
				li {
					display: flex;
					justify-content: center;
					a {
						span {
							display: none;
						}
					}
				}
				.icon1 {
					display: block;
					background-image: url('.././assets/img/facebook.svg');
					background-size: contain;
					height: 18px;
					width: 18px;
					background-repeat: no-repeat;
				}
				.icon2 {
					display: block;
					background-image: url('.././assets/img/instagram.svg');
					background-size: contain;
					height: 19px;
					width: 18px;
					background-repeat: no-repeat;
				}
				.icon3 {
					display: block;
					background-image: url('.././assets/img/youtube.svg');
					background-size: contain;
					height: 18px;
					width: 20px;
					background-repeat: no-repeat;
				}
			}
		}
		.scroll-to-top {
			.arrow-up {
				padding-left: 0.5vw;
				img {
					width: 12px;
				}
			}
		}
	}

	@media (max-width: 640px) {
		footer {
			flex-direction: column-reverse;
			padding: 14px 0 14px 0;
			p {
				padding-top: 5px;
			}
			.social {
				width: 80%;
			}

			div:first-child {
				margin-top: 3vw;
				text-align: center;
				width: 100%;
			}

			.scroll-to-top {
				display: none;
			}
		}
	}
}
</style>
