<template>
	<div>
		<div class="rearing_container">
			<div class="container">
				<div class="about">
					<h2>{{ $t(`${$route.params.name}.header.title`) }}</h2>
					<p>{{ $t(`${$route.params.name}.header.desc`) }}</p>
				</div>
			</div>
			<div class="multi_gallery_container" v-if="$route.params.name === 'referencia'">
				<VideoGallery :videos="videos" />
			</div>
			<div class="block" v-for="(block, index) in shownBlocks" :key="index">
				<Block :data="block" :class="{ last: index === shownBlocks.length - 1, first: index === 0 }" />
			</div>

			<div class="more-content" v-if="blocks.length > shownBlocks.length">
				<div @click="cardsCounter += 3">
					<span class="more-link">
						{{ $t(`link.more_content`) }}
					</span>
					<span class="arrow"><img src=".././assets/img/arrow-down.svg" alt="" /></span>
				</div>
			</div>

			<Footer />
		</div>
	</div>
</template>

<script>
import Footer from '../components/Footer.vue';
import blocks from '../blocks.js';
import galleries from '../galleries.js';
import VideoGallery from '../components/VideoGallery.vue';
import Block from '../components/Block.vue';

export default {
	components: {
		Footer,
		VideoGallery,
		Block,
	},
	data: () => ({
		blocks: blocks,
		videos: [],
		cardsCounter: 3,
	}),

	computed: {
		shownBlocks() {
			return this.blocks.slice(0, this.cardsCounter);
		},
	},

	created() {
		if (this.$route.params.name === 'tenyesztes' || this.$route.params.name === 'referencia') {
			this.blocks = this.blocks[this.$route.params.name];
			this.videos = galleries[this.$route.params.name];
		}
	},
};
</script>

<style lang="scss" scoped>
.rearing_container {
	padding: 0 2vw 0 2vw;
	margin: auto;
	max-width: 1920px;
	text-align: left;

	.about {
		padding: 4vw 15vw 0 15vw;
	}

	h2 {
		font-size: 3rem;
		text-transform: uppercase;
		text-align: center;
	}

	p {
		margin: auto;
		margin-bottom: 5vw;
		text-align: center;
	}
	.multi_gallery_container {
		padding-top: 3vw;
		padding-bottom: 4vw;
	}
	.more-content {
		padding: 10vw 0 10vw 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		:hover {
			color: hsl(37, 28%, 87%, 0.7) !important;
			transition: 0.5s ease-out;
		}
		div {
			display: flex;
			flex-direction: column;
			font-size: 0.7rem;
			align-items: center;
			cursor: pointer;
			height: 80px;

			.arrow {
				transition: all 0.2s ease;
				margin-top: 5px;
			}
			&:hover .arrow {
				margin-top: 15px;
			}
		}
	}
}
@media (max-width: 1024px) {
	.rearing_container {
		padding: 0;
		h2 {
			font-size: 2.5rem;
		}
		.about {
			margin: 20vw 0 10vw 0;
			padding: 0;
		}
		p {
			margin: auto;
			width: 90%;
		}
		.multi_gallery_container {
			padding-top: 0;
		}
		.block {
			margin-top: 0;
		}

		.block.first {
			margin-top: 2vw;
			border-top: none;
		}
	}
}
@media (max-width: 640px) {
	.rearing_container {
		.block.first {
			padding-top: 0;
			margin-top: 0;
		}
		.about {
			padding: 0;
			margin: 25vw 0 20vw 0;

			p {
				width: 100%;
			}
		}
		h2 {
			font-size: 2rem;
			margin-bottom: 5vw;
		}

		.description_intro {
			padding-bottom: 7vw;
		}
		.multi_gallery_container {
			padding-top: 0;
		}

		.more-content {
			padding: 15vw 0 28vw 0;

			div .arrow {
				margin-top: 15px;
			}

			a {
				.arrow {
					padding-top: 0.7vw;
					-webkit-transition: none !important;
					-moz-transition: none !important;
					-o-transition: none !important;
					-ms-transition: none !important;
					transition: none !important;
					margin-top: 0;
				}

				&:hover .arrow {
					-webkit-transition: none !important;
					-moz-transition: none !important;
					-o-transition: none !important;
					-ms-transition: none !important;
					transition: none !important;
					margin-top: 0;
				}

				.more-link {
					margin-bottom: 3vw;
				}
			}
		}

		p {
			margin-top: 5vw;
		}
	}
}
</style>
