<template>
	<div class="rearing">
		<div class="content-container">
			<div class="left-container">
				<div class="title">
					<h2>{{ data.title }}</h2>
					<p>{{ $t(`${$route.params.name}.${data.gender}`) }}</p>
					<p>{{ data.data }}</p>
					<div class="flex">
						<p>{{ $t(`tenyesztes.birth_date`) }}:</p>
						<p>{{ data.birth_date_year }}</p>
					</div>
				</div>
			</div>
			<div class="right-container">
				<Gallery
					:slides="data.slides"
					type="block"
					:slides-per-view="1"
					:breakpoints="{
						640: {
							slidesPerView: 'auto',
							spaceBetween: 40,
						},
					}"
					:space-between="10"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Gallery from '../components/Gallery.vue';
export default {
	props: {
		data: Object,
	},
	components: {
		Gallery,
	},
};
</script>

<style lang="scss" scoped>
$text_color: #e8e1d6;

.rearing {
	&.last {
		.content-container {
			border-bottom: 0;
		}
	}

	&.first {
		.content-container {
			border-top: 1px solid white;
		}
	}
}
.content-container {
	display: flex;
	border-bottom: 1px solid $text_color;

	.left-container {
		border-right: 1px solid $text_color;
		padding-right: 2vw;
		width: 35%;
		flex: none;

		.date-container {
			display: flex;
			justify-content: space-between;
			margin: 2vw 0 2vw 0;
		}
		.title {
			h2 {
				text-align: left;
				line-height: 1.1;
				width: 81%;
				margin: 2vw 0 1vw;
			}
			p {
				text-align: left;
				//margin: 1vw 0 3vw 0;
			}

			.flex {
				display: flex;

				p:first-child {
					margin-right: 0.8vw;
				}
			}
		}
		.text {
			width: 87%;
			margin-top: 2vw;
			margin: 0vw 0 3vw 0;
			text-align: left;
		}
	}

	.right-container {
		height: 40vw;
		width: 62%;
		padding-bottom: 4vw;
		margin-left: 3%;
		margin-top: 3%;
		margin-bottom: 2vw;
		position: relative;
	}
}

@media (max-width: 1024px) {
	.content-container {
		flex-direction: column;
		margin-right: 0;
		margin-top: 8vw;
		padding-bottom: 7vw;
		.left-container {
			width: auto;
			border-right: none;
			padding-right: 0;
			.title {
				margin-top: 2vw;
				margin-bottom: 5vw;
				h2 {
					font-size: 2.5rem;
				}
			}
			.text {
				margin-top: 5vw;
				margin-bottom: 5vw;
				text-align: left;
				width: auto;
			}
			.date-container {
				margin-bottom: 4vw;
				border-top: 1px solid $text_color;
				padding-top: 3vw;
			}
		}
		.right-container {
			height: 60vw;
			width: auto;
			margin: 0;
			padding-top: 0;
			padding-bottom: 8vw;
		}
	}
}
@media (max-width: 640px) {
	.content-container {
		.left-container {
			padding-right: 0;
			.title {
				order: 2;
				margin-bottom: 14vw;
				h2 {
					font-size: 2rem;
					width: 93%;
					margin: 2vw 0 4vw;
				}
				span {
					order: 3;
				}
				p {
					font-size: 1rem;
					margin: 0.5vw 0;
				}
			}
			.text {
				order: 5;
			}
			.date-container {
				order: 2;
				margin-top: 1vw;
				padding-top: 11vw;
				font-size: 0.7rem;
			}
		}

		.right-container {
			height: 72vw;
			margin-bottom: 0;
			padding-bottom: 64px;
		}
	}
}
</style>
