<template>
	<div>
		<div class="container">
			<Heading :title="$t(`title.home`)" :description="$t(`description.home.desc1`)" />
			<Riders />
			<div class="actuality-gallery">
				<Gallery type="actuality" :slides="slides" arrow-position="arrow-top" />
			</div>

			<Partners />
			<Contact />
			<Footer />
		</div>
	</div>
</template>

<script>
import Heading from '../components/Heading.vue';
import Riders from '.././components/Riders.vue';
import Contact from '../components/Contact.vue';
import Gallery from '../components/Gallery.vue';
import Partners from '../components/Partners.vue';
import Footer from '../components/Footer.vue';

export default {
	name: 'Home',

	components: {
		Heading,
		Riders,
		Gallery,
		Partners,
		Contact,
		Footer,
		// Popup,
	},

	data() {
		return {
			popUpIsOpen: false,

			slides: [
				{ type: 'image', url: '/img/balazs-header.jpg' },
				{ type: 'image', url: '/img/balazs-header.jpg' },
				{ type: 'image', url: '/img/balazs-header.jpg' },
				{ type: 'image', url: '/img/balazs-header.jpg' },
				{ type: 'image', url: '/img/balazs-header.jpg' },
				{ type: 'image', url: '/img/balazs-header.jpg' },
				{ type: 'image', url: '/img/balazs-header.jpg' },
				{ type: 'image', url: '/img/balazs-header.jpg' },
				{ type: 'image', url: '/img/balazs-header.jpg' },
			],
		};
	},
	mounted() {
		let self = this;
		setTimeout(function () {
			fetch(
				'https://graph.instagram.com/me/media?fields=media_url,media_type,permalink&access_token=IGQWRNUEJEM3FBb0d6VktIUzdRUFlpeGFhMGMzS0pXdmd6bXpGb0lUdzY3VTl1R2l5eEJJMG1DbUc1OWw5cmFsc2s1VXlEY1lVRGpKQnNneG1SMENKVXliY1VNYy05RDc4NXNRWXh6dVJaT1dMNnNNLVNVV2JwWG8ZD'
			)
				.then(response => response.json())
				.then(data => {
					self.slides = [];
					data.data
						.filter(m => m.media_type === 'IMAGE')
						.slice(0, 9)
						.map(d => {
							self.slides.push({ type: 'image_link', url: d.media_url, link: d.permalink });
							return d;
						});
				});
		}, 0);
	},
};
</script>

<style lang="scss" scoped>
.container {
	padding: 0 2vw 0 2vw;
	margin: auto;
	max-width: 1920px;

	.actuality-gallery {
		height: 30vw;
		padding-top: 70px;
		position: relative;
	}
}
.landing-page {
	background-image: url('.././assets/img/header.jpeg');
	background-size: cover;
	background-position: center;
	height: 100vh;
	text-align: center;
	animation: landing-page-logo-load 2s;
	animation-delay: 0.5s;
	opacity: 0;
	animation-fill-mode: forwards;

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background: #1b2b3d;
		left: 0;
		z-index: 1;
		animation: landing-page-bg-load 2s;
		animation-delay: 0.5s;
		opacity: 0;
		animation-fill-mode: forwards;
	}
	img {
		margin: 25vh 0 auto;
		width: 30vw;
		position: relative;
		z-index: 2;
	}

	@keyframes landing-page-logo-load {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes landing-page-bg-load {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}
}

@media (max-width: 1024px) {
	#app {
		overflow: hidden;
		padding: 0 2vw 0 2vw;
	}
	.container {
		padding: 0;

		.actuality-gallery {
			height: 60vw;
		}
	}
	.landing-page {
		margin: 0 auto;
		width: 100%;
		text-align: center;
		margin-top: 18vw;
		img {
			width: 50vw;
		}
	}
}
@media (max-width: 640px) {
	.container {
		.actuality-gallery {
			height: 85vw;
		}
	}
	.landing-page {
		img {
			margin: 30vh 0 auto;
		}
	}
}
</style>
