import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Navbar from '@/components/Navbar.vue';
import { i18n } from 'vue-lang-router';
import './assets/global.scss';

let app = createApp(App);

app.component('Navbar', Navbar);

app.config.globalProperties.window = window;
app.config.globalProperties.document = document;

app.use(router);
app.use(i18n);
app.mount('#app');
