<template>
	<div>
		<div class="riders-container">
			<div class="card_1">
				<div>
					<h2 class="names">
						{{ $t(`szabolcs.introduction.name`) }}
					</h2>
				</div>

				<localized-link to="/rolunk/szabolcs" @mouseenter="hover = 'szabolcs'" @mouseleave="hover = 0">
					<div class="img-container" :class="{ is_hover: hover == 'szabolcs' }">
						<img class="images" src=".././assets/img/szabolcs.jpeg" alt="Szabolcs" loading="lazy" decoding="auto" />
					</div>
					<div class="links">
						<span class="spanHover"> {{ $t(`link.next`) }} </span>
						<span class="arrow">
							<img src=".././assets/img/arrow-right.svg" alt="" />
						</span>
					</div>
				</localized-link>
			</div>

			<div class="card_2">
				<div>
					<h2 class="names">{{ $t(`balazs.introduction.name`) }}</h2>
				</div>

				<localized-link @mouseenter="hover = 'balazs'" @mouseleave="hover = 0" to="/rolunk/balazs">
					<div class="img-container" :class="{ is_hover: hover == 'balazs' }">
						<img class="images" src="../assets/img/img2.jpg" alt="Balázs" loading="lazy" decoding="auto" />
					</div>
					<div class="links">
						<span class="spanHover"> {{ $t(`link.next`) }} </span>
						<span class="arrow">
							<img src=".././assets/img/arrow-right.svg" alt="" />
						</span>
					</div>
				</localized-link>
			</div>
		</div>

		<section class="highlight video_design">
			<h2 class="h-ref">{{ $t(`title.highlight`) }}</h2>

			<VideoGallery :videos="videos" />
			<p>
				{{ $t(`description.home.desc2`) }} <br />
				<span class="name1">{{ $t(`szabolcs.introduction.name`) }}</span>
			</p>
		</section>
		<div class="spam">
			<div class="box1"></div>
			<div class="box2"></div>
		</div>
		<div class="actuality">
			<h2>{{ $t(`title.actuality`) }}</h2>
			<a href="https://www.instagram.com/krucsolovarda/" target="_blank">@krucsolovarda</a>
		</div>
	</div>
</template>

<script>
import VideoGallery from '../components/VideoGallery.vue';

export default {
	components: {
		VideoGallery,
	},
	data: () => ({
		hover: 0,

		videos: [
			{
				title: 'European Championship',
				desc: '2017 Göteborg, Szabolcs Krucsó and Chacco Blue II',
				url: 'ohYBW4tM1-g',
			},
			{
				title: 'Aachen Olympic Qualifier',
				desc: 'Balázs Krucsó and KN Nemo',
				url: 'QVBH6nGm3DI',
			},
			{
				title: 'Young Horses World Championship',
				desc: '2012 Lanaken (Belgium), 7-year-old category, Szabolcs Krucsó and Chacco Boy',
				url: 'dG78NTTiDgo',
			},
		],
	}),
};
</script>

<style lang="scss" scoped>
$text_color: #e8e1d6;
.riders-container {
	display: flex;
	width: 100%;
	flex: 1 1 100%;
	align-items: center;
	justify-content: center;

	.card_1 {
		width: 50%;
		display: flex;
		flex: 1 1 100%;
		flex-direction: column;

		.links {
			padding: 2vw 0 2vw 0;
			width: 100%;
			display: flex;
			justify-content: flex-start;
			border-bottom: 1px solid $text_color;
			border-right: 1px solid $text_color;

			&:hover {
				.arrow {
					padding-left: 25px;
				}
			}
		}

		.spanHover {
			color: $text_color;
		}
		.arrow {
			width: 75px;
			padding-left: 10px;
			transition: ease-in 0.2s;
		}
		.img-container {
			border-right: 1px solid rgb(255, 255, 255);
			padding-right: 3vw;
			height: 48vw;
			max-height: 908px;
		}
	}
	.card_2 {
		width: 50%;
		display: flex;
		flex: 1 1 100%;
		flex-direction: column;
		text-align: right;

		.links {
			padding: 2vw 0 2vw 0;
			border-bottom: 1px solid $text_color;
			border-right: none;
			width: 100%;
			display: flex;
			justify-content: flex-end;

			&:hover {
				.arrow {
					padding-right: 0;
				}
			}
		}

		.spanHover {
			color: $text_color;
		}
		.arrow {
			width: 75px;
			transition: ease-in 0.2s;
			padding-right: 15px;
		}

		.img-container {
			padding-left: 3vw;
			height: 48vw;
			max-height: 908px;
		}

		p {
			text-align: right;
		}
	}

	.names {
		margin-top: 8vw;
		padding-bottom: 1vw;
	}

	.img-container {
		border-top: 1px solid rgb(255, 255, 255);
		border-bottom: 1px solid rgb(255, 255, 255);
		padding: 3vw 0 3vw 0;
		position: relative;
		&:after {
			transition: 0.5s ease-in-out;
			position: absolute;
			width: 100%;
			height: 100%;
			content: '';
			display: block;
			top: 0;
			left: 0;
		}

		&.is_hover {
			&:after {
				transition: 0.5s ease-in-out;
				background: rgba(27, 43, 61, 0.5);
				opacity: 1;
			}
		}

		img {
			width: 100%;
			background: beige;
			height: 100%;
			object-position: center top;
			object-fit: cover;
			p {
				text-align: center;
				font-size: 2.8vw;
				font-size: 3vw;
				line-height: 3vw;
				padding: 5vw 1vw 0vw;
			}
		}
		.names {
			line-height: 5rem;
			font-size: 2rem;
		}
	}
}

.highlight {
	color: $text_color;
	display: flex;
	padding: 10vw 0 0 0;
	justify-content: center;
	flex-direction: column;

	h2 {
		text-transform: uppercase;
		padding: 0 0 5vw 0;
		text-align: center;
	}

	p {
		text-align: center;
		font-size: 3rem;
		line-height: 1.2;
		width: 76%;
		margin: 10vw auto;

		.name1 {
			font-size: 1rem;
			margin-top: 3vw;
			display: block;
		}
	}
}

.spam {
	display: flex;
	border-bottom: 1px solid $text_color;
	margin-top: -4vw;

	.box1 {
		height: 10vw;
		width: 50vw;
		border-right: 1px solid $text_color;
	}
	.box2 {
		height: 10vw;
		width: 50vw;
	}
}

.actuality {
	text-align: center;
	margin-top: 1vw;
	padding: 9vw 0 9vw 0;
	text-transform: uppercase;
	a {
		font-size: 1rem;
		text-decoration: none;
		color: $text_color;
		letter-spacing: 0;
		display: inline-block;
		text-transform: lowercase;
		margin-top: 20px;
	}
}

@media (max-width: 1024px) {
	.riders-container {
		flex-wrap: wrap;

		.images {
			display: flex;
			width: 100%;
		}

		.img-container {
			padding: 5vw 0 5vw 0;
			max-height: auto;
			img {
				height: 700px;
			}
		}

		.card_1 {
			.img-container {
				border-right: none;
				padding-right: 0;
				height: auto;
				max-height: none;
			}
			.links {
				border-right: none;
				padding: 1rem 0 1rem 0;

				justify-content: space-between;
			}
		}

		.card_2 {
			.names {
				text-align: left;
			}
			.img-container {
				padding-left: 0;
				height: auto;
				max-height: none;
			}
			.links {
				padding: 1rem 0 1rem 0;
				justify-content: space-between;
			}
		}
		.names {
			font-size: 2rem;
			margin-top: 14vw;
			padding-bottom: 2vw;
		}

		.links {
			padding: 3vw 0 3vw 0;
			display: flex;
			flex-direction: row;

			a {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}

			.arrow {
				display: flex;
				padding-right: 0;
				width: auto;
				img {
					width: 40px;
				}
			}
		}
	}

	.highlight {
		.h-ref {
			font-size: 2.5rem;
			margin: auto;
			width: 314px;
			margin-bottom: 12vw;
			line-height: 1;
		}
		p {
			font-size: 3rem;
			line-height: 1.2;
			padding: 0;
			width: 100%;
			margin-bottom: 4vw;

			.name1 {
				font-size: 1rem;
				display: block;
				margin-top: 4vw;
			}
		}
	}
	.spam {
		margin-top: 7vw;
	}
	.actuality {
		h2 {
			font-size: 2.5rem;
		}
	}
}

@media (max-width: 640px) {
	.riders-container {
		.img-container img {
			height: 290px;
		}
		.links {
			padding: 5vw 0 5vw 0;
			a {
				width: 100%;
			}
		}
		.names {
			font-size: 1.3rem;
			padding-bottom: 3vw;
			font-weight: 100;
		}
		.card_1 {
			.img-container {
				padding: 6vw 0 6vw 0;
			}
		}
	}
	.highlight {
		padding: 18vw 0 0 0;
		h2 {
			padding-bottom: 9vw;
			line-height: 1.1;
		}

		.h-ref {
			width: 276px;
			font-size: 2rem;
		}
		p {
			line-height: 1.1;
			margin: 10vw 0 0 0;
			font-size: 1.7rem;

			.name1 {
				margin-top: 9vw;
				font-size: 1rem;
			}
		}
	}
	.spam {
		.box1 {
			height: 17vw;
		}
		.box2 {
			height: 17vw;
		}
	}
	.actuality {
		padding: 17vw 0 12vw 0;
		h2 {
			font-size: 2rem;
		}
	}
	.card_2 {
		margin-top: 3vw;
		.img-container {
			padding: 6vw 0 6vw 0;
		}
	}
}
@media (min-width: 1920px) {
	.riders-container {
		.links {
			padding: 0.5vw 0 0.5vw 0;
		}
	}
	.highlight {
		h2 {
			padding: 0px 0px 7vw 0;
		}
	}
	.actuality {
		padding: 6vw 0 5vw 0;
	}
}
</style>
