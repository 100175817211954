<template>
	<div>
		<div class="about">
			<div class="container">
				<div class="bg-img">
					<img :src="'/img/' + $route.params.name + '-header.jpg'" alt="" />
				</div>

				<div class="intro">
					<div class="card1">
						<h2 class="name">
							{{ $t(`${$route.params.name}.introduction.name`) }}
						</h2>
						<p class="about-text">
							{{ $t(`${$route.params.name}.introduction.desc`) }}
						</p>
					</div>
					<div>
						<img :src="`/img/${$route.params.name}-intro.jpg`" alt="" />
					</div>
				</div>
				<div class="testimonial">
					<h2 class="introduction-text">
						<p>{{ $t(`${$route.params.name}.quote.desc`) }}</p>
						<p>
							{{ $t(`${$route.params.name}.quote.writer`) }}
						</p>
					</h2>
				</div>

				<div class="info-with-img">
					<div>
						<img class="img1" :src="`/img/${$route.params.name}-story.jpg`" alt="" />
					</div>
					<div>
						<h2 class="title2">
							{{ $t(`${$route.params.name}.story.title`) }}
						</h2>
						<p>{{ $t(`${$route.params.name}.story.desc`) }}</p>
					</div>
				</div>

				<div class="info-witout-img">
					<div>
						<h2 class="career_title">
							{{ $t(`${$route.params.name}.career.title`) }}
						</h2>
					</div>
					<div class="story-text">
						<div>
							<p class="career-text">
								{{ $t(`${$route.params.name}.career.dec`) }}
							</p>
							{{ $t(`${$route.params.name}.career.desc3`) }}
						</div>

						<div class="right-content">
							<p>
								{{ $t(`${$route.params.name}.career.desc2`) }}
							</p>
							<p class="introduction_szabolcs">
								{{ $t(`${$route.params.name}.career.desc4`) }}
							</p>
						</div>
					</div>
				</div>
				<div class="about-gallery">
					<Gallery
						:slides="gallery.slides"
						:slides-per-view="1"
						:breakpoints="{
							640: {
								slidesPerView: 'auto',
								spaceBetween: 40,
							},
						}"
						:space-between="10"
						type="about"
					/>
				</div>

				<div class="other-rider" :class="[{ is_hover: hover }]">
					<div class="link">
						<localized-link
							:to="`/rolunk/${getCurrentName()}`"
							class="contact-text"
							@mouseenter="hover = !hover"
							@mouseleave="hover = !hover"
						>
							<span class="spanHover">{{ $t(`${$route.params.name}.introduction.linkName`) }} </span>
							<span class="arrow"><img src=".././assets/img/arrow-right.svg" alt="" /></span
						></localized-link>
					</div>
					<div class="image">
						<img :src="`/img/${$route.params.name}-redirect.jpg`" alt="" />
					</div>
				</div>
				<Footer />
			</div>
		</div>
	</div>
</template>

<script>
import Gallery from '../components/Gallery.vue';
import Footer from '../components/Footer.vue';
import galleries from '../galleries.js';

export default {
	name: 'About',
	components: {
		Gallery,
		Footer,
	},
	data: () => ({
		gallery: [],
		galleries: galleries,
		hover: false,
	}),
	created() {
		if (this.$route.params.name === 'balazs' || this.$route.params.name === 'szabolcs') {
			this.gallery = this.galleries['about'][this.$route.params.name];
		} else {
			window.location.replace('/404');
		}
	},
	methods: {
		getCurrentName: function () {
			return this.$route.params.name === 'szabolcs' ? 'balazs' : 'szabolcs';
		},
	},
};
</script>

<style lang="scss" scoped>
$text_color: #e8e1d6;
.container {
	padding: 0 2vw 0 2vw;
	margin: auto;
	max-width: 1920px;

	.about-gallery {
		height: 670px;
		padding-bottom: 70px;
		margin-bottom: 4vw;
		position: relative;
	}

	.bg-img {
		img {
			width: 100%;
			border-bottom: 1px solid $text_color;
			padding-bottom: 2.8vw;
			margin-top: 2.8vw;
			height: 850px;
			object-fit: cover;
			object-position: top center;
		}
	}

	.intro {
		display: flex;
		border-bottom: 1px solid $text_color;
		padding-bottom: 2.5vw;
		padding-top: 2.5vw;

		div:first-child {
			margin-right: 4vw;
			width: 90%;
		}

		h2 {
			padding-bottom: 1vw;
		}

		div {
			flex: 1;

			img {
				width: 100%;
				height: 680px;
				object-fit: cover;
				object-position: center top;
			}
		}

		.card1 {
			.about-text {
				flex: 1;
			}
		}
	}

	.testimonial {
		padding: 4vw 30vw 4vw 0;
		h2 {
			font-size: 2.5rem;
			line-height: 1.2;
		}
	}

	.info-with-img {
		border-bottom: 1px solid $text_color;
		border-top: 1px solid $text_color;
		padding: 2.7vw 0 2.7vw 0;
		display: flex;

		div:last-child {
			margin-left: 2.7vw;
		}

		h2 {
			padding: 0 10vw 2vw 0;
			line-height: 1.1;
		}

		> div {
			flex: 1;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top;
			}
		}

		div:first-child {
			height: 700px;
		}
	}

	.info-witout-img {
		display: flex;
		flex-direction: column;
		margin-bottom: 3vw;
		.last-text {
			margin-bottom: 164px;
		}

		div:last-child {
			display: flex;
			gap: 4vw;

			div {
				flex: 1;
			}
		}

		h2 {
			padding: 0 32vw 2vw 0vw;
			padding-top: 4vw;
			padding-bottom: 2vw;
			width: 87%;
			line-height: 1.1;
		}

		div {
			flex: 1;
		}
		.story-text {
			display: flex;
			gap: 7rem;
			padding-bottom: 3vw;
			border-bottom: 1px solid $text_color;

			.right-text {
				margin-top: 1vw;
			}

			.right-content {
				display: flex;
				gap: 0;
				flex-direction: column;
			}
			.new-text {
				margin-top: 1vw;
			}
			.introduction_szabolcs {
				margin-top: 1vw;
			}
			.career-text {
				margin-bottom: 1vw;
			}
		}
	}

	.other-rider {
		display: flex;
		border-top: 1px solid $text_color;
		height: 45vw;

		&.is_hover {
			div {
				&.link {
					.contact-text {
						span.arrow {
							margin-left: 20px;
							transition: ease-in 0.2s;
						}
					}
				}
				&.image {
					&:after {
						transition: 0.5s ease-in-out;
						background: rgba(27, 43, 61, 0.5);
						opacity: 1;
					}
				}
			}

			&:after {
				transition: 0.5s ease-in-out;
				background: rgba(27, 43, 61, 0.5);
				opacity: 1;
			}
		}

		div {
			flex: 1;

			&.image {
				position: relative;
				display: flex;
				height: 100%;
				width: 100%;
				align-items: center;
				justify-content: flex-end;

				&:after {
					transition: 0.5s ease-in-out;
					position: absolute;
					width: 100%;
					height: 100%;
					content: '';
					display: block;
					top: 0;
				}
				img {
					height: 100%;
					width: 100%;
					padding: 2vw 0 2vw 2vw;
					object-fit: cover;
				}
			}

			&.link {
				border-right: 1px solid $text_color;
				display: flex;
				justify-content: center;
				align-items: center;

				.contact-text {
					span {
						transition: ease-in 0.2s;
						font-size: 3rem;
					}

					img {
						display: block;
						margin-top: 1vw;
						width: 40px;
					}

					&:hover span.arrow {
						margin-left: 5px;
						transition: ease-in 0.2s;
					}
				}

				a {
					display: flex;
					flex-direction: column;
				}
				img {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: 1024px) {
	.container {
		padding: 0;

		.about-gallery {
			height: 470px;
			padding-bottom: 70px;
		}

		.bg-img {
			img {
				border-bottom: 1px solid $text_color;
				padding-top: 15.5vw;
				padding-bottom: 5vw;
				height: 690px;
			}
		}
		h2 {
			font-size: 2rem;
		}
		.intro {
			flex-direction: column;
			margin-top: 0;
			display: flex;
			border-bottom: 1px solid $text_color;
			padding-bottom: 4vw;
			padding-top: 2.5vw;

			h2 {
				padding: 0;
			}
			.card1 {
				.about-text {
					font-size: 1rem;
					width: 103%;
					margin: 4vw 0 6vw 0;
				}
				.name {
					margin-top: 1vw;
				}
			}
		}
		.info-with-img {
			flex-direction: column-reverse;
			padding-bottom: 4vw;
			h2 {
				width: 90%;
				line-height: 1.1;
				margin-top: 4vw;
			}
			div:last-child {
				margin: 0;
			}

			p {
				margin-top: 3vw;
				margin-bottom: 3vw;
			}

			> div {
				height: 700px;
				flex: none;
			}

			div:last-child {
				height: auto;
			}
		}
		.testimonial {
			padding: 6vw 30vw 6vw 0;

			h2 {
				width: 120%;
			}
		}
		.info-witout-img {
			flex-direction: column;
			h2 {
				padding: 6vw 32vw 6vw 0vw;
				width: 116%;
				line-height: 1.1;
			}
			div:last-child {
				flex-direction: column;
			}
			.last-text {
				margin-top: -2vw;
				margin-bottom: 7vw;
			}
		}
		.other-rider {
			flex-direction: column;
			height: auto;

			div.image {
				img {
					height: 92vw;
				}
			}

			&.balazs {
				flex-direction: column;
				div.link {
					border-left: none;
				}

				div.image {
					position: relative;
					img {
						padding: 5vw 0 5vw 0;
					}
				}
			}

			div.link {
				padding: 15vw 0 15vw 0;
				border-right: none;
				border-left: none;
				border-bottom: 1px solid $text_color;
				display: flex;
				justify-content: flex-start;

				.contact-text {
					font-size: 3rem;

					&:hover span.arrow {
						margin-left: 0;
						transition: none;
					}
				}

				:hover {
					color: rgb(232, 225, 214, 0.7);
					margin-right: 0;

					.arrow {
						margin-left: 0;
						transition: none;
					}
				}
			}

			div.image {
				padding: 0;

				img {
					padding: 5vw 0 5vw 0;
				}
			}
		}
	}
}

@media (max-width: 640px) {
	.container {
		.about-gallery {
			height: 80vw;
		}

		h3 {
			width: 100%;
			border-top: 1px solid $text_color;
		}
		.bg-img {
			margin-top: 1vw;
			img {
				padding-top: 16vw;
				padding-bottom: 6.5vw;
				height: 290px;
			}
		}

		.intro {
			div img {
				height: 345px;
			}
			h2 {
				margin-top: 6vw 0 0vw 0;
				margin-top: 4vw;
				margin-bottom: 6vw;
			}
			.card1 {
				.about-text {
					margin-bottom: 10vw;
					width: 102%;
				}
				.name {
					font-size: 2rem;
					margin-top: 4vw;
				}
			}
		}
		p {
			font-size: 1.1rem;
			line-height: 1.5;
			width: 92%;
		}

		.introduction-text {
			font-size: 2rem;
			line-height: 1.2;
			margin-top: 4vw;
			margin-bottom: 0vw;
			width: 154%;
		}
		.info-with-img {
			flex-direction: column;
			border-top: none;

			h2 {
				font-size: 1.5rem;
				line-height: 1.1;
				width: 95%;
				margin-bottom: 6vw;
			}

			div:last-child {
				margin: 0;
			}
			img {
				padding-top: 6vw;
				padding-bottom: 6vw;
				border-top: 1px solid #ffffff;
			}
			.title2 {
				font-size: 2rem;
				width: 111%;
			}
		}

		.info-witout-img {
			padding: 6vw 0 3vw 0;
			h2 {
				font-size: 2rem;
				width: 100%;
				line-height: 1.1;
			}
			:last-child {
				line-height: 1.5;
			}
			.story-text {
				.introduction_szabolcs {
					margin-top: 3vw;
					margin-bottom: 3vw;
				}
				.new-text {
					margin-top: 6vw;
					line-height: 1.5;
					margin-bottom: 6vw;
				}
			}
			.last-text {
				margin-bottom: 8vw;
				margin-top: 8vw;
			}
			.career_title {
				line-height: 1.1;
			}
		}
		.other-rider {
			div.link {
				.contact-text {
					span {
						font-size: 2rem;
					}
				}
			}
		}
		.testimonial {
			padding: 15vw 30vw 15vw 0;

			h2 {
				width: 140%;
				font-size: 2rem;
			}
		}
	}
}

@media (min-width: 1920px) {
	.container {
		.about-gallery {
			height: 30vw;
		}
		.intro {
			.card1 {
				.about-text {
					font-size: 1.8rem;
					line-height: 40px;
				}
			}
		}
		.other-rider {
			div.link {
				.contact-text span {
					margin-top: 0;
				}
			}
		}
	}
}
</style>
