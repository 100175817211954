<template>
	<div class="landing-page">
		<img src=".././assets/img/logo.svg" alt="logo" />
	</div>
</template>
<style lang="scss" scoped>
.landing-page {
	background-image: url('.././assets/img/header.jpeg');
	background-size: cover;
	background-position: center;
	height: 100vh;
	text-align: center;
	animation: landing-page-logo-load 2s;
	animation-delay: 0.5s;
	opacity: 0;
	animation-fill-mode: forwards;

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		background: #1b2b3d;
		left: 0;
		z-index: 1;
		animation: landing-page-bg-load 2s;
		animation-delay: 0.5s;
		opacity: 0;
		animation-fill-mode: forwards;
	}
	img {
		margin: 25vh 0 auto;
		width: 30vw;
		position: relative;
		z-index: 2;
	}

	@keyframes landing-page-logo-load {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes landing-page-bg-load {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}
}
@media (max-width: 1024px) {
	#app {
		overflow: hidden;
		padding: 0 2vw 0 2vw;
	}
	.container {
		padding: 0;
	}
	.landing-page {
		margin: 0 auto;
		width: 100%;
		text-align: center;
		margin-top: 18vw;
		img {
			width: 50vw;
		}
	}
}
@media (max-width: 640px) {
	.landing-page {
		img {
			margin: 30vh 0 auto;
		}
	}
}
</style>
