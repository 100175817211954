import { createWebHistory } from 'vue-router';
import { createLangRouter } from 'vue-lang-router';

import translations from '@/lang/translations';
import localizedURLs from '@/lang/localized-urls';

import Home from '../views/Home.vue';
import About from '../views/About.vue';
import ContactUs from '../views/ContactUs.vue';
import RidingHall from '../views/RidingHall';
import Rearing from '../views/Rearing.vue';
import NotFound from '../views/NotFound.vue';

const langRouterOptions = {
	defaultLanguage: 'hu',
	translations,
	localizedURLs,
};

export const routes = [
	{
		name: 'Home',
		path: '/',
		component: Home,
	},
	{
		name: 'About',
		path: '/rolunk/:name',
		component: About,
		props: true,
	},
	{
		name: 'Contact',
		path: '/kapcsolat',
		component: ContactUs,
		props: true,
	},
	{
		name: 'RidingHall',
		path: '/lovarda',
		component: RidingHall,
		props: true,
	},
	{
		name: 'Rearing',
		path: '/info/:name',
		component: Rearing,
		props: true,
	},
	{
		name: '404',
		path: '/404',
		component: NotFound,
	},
];

const routerOptions = {
	routes,
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior() {
		return { top: 0 };
	},
};

const router = createLangRouter(langRouterOptions, routerOptions);

router.beforeEach(async (to) => {
	if (to.path.includes('/hu')) {
		return to.path.replace('/hu', '');
	}
});

export default router;
