const blocks = {
	referencia: [],
	tenyesztes: [
		{
			title: 'Chesterfield',
			gender: 'female',
			data: 'Chacco-Blue II x All in One',
			birth_date_year: '2016',

			slides: [
				{
					type: 'image',
					url: '/img/chesterfield1.jpg',
				},
				{
					type: 'image',
					url: '/img/chesterfield2.jpg',
				},
				{
					type: 'image',
					url: '/img/chesterfield3.jpg',
				},
				{
					type: 'image',
					url: '/img/chesterfield4.jpg',
				},
			],
		},
		{
			title: 'Bonanza',
			gender: 'female',
			data: 'Baloubet du Rouet x Samico',
			birth_date_year: '2010',
			slides: [
				{
					type: 'image',
					url: '/img/bonanza1.jpg',
				},
				{
					type: 'image',
					url: '/img/bonanza4.jpg',
				},
				{
					type: 'image',
					url: '/img/bonanza3.jpg',
				},
				{
					type: 'image',
					url: '/img/bonanza2.jpg',
				},
			],
		},
		{
			title: 'Kaplankaya',
			gender: 'male',
			data: 'Kannan x Heartbreaker',

			birth_date_year: '2017',
			slides: [
				{
					type: 'image',
					url: '/img/kaplankaya1.jpg',
				},
			],
		},
	],
};

export default blocks;
