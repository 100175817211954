<template>
	<MainImage v-if="$route.name == 'Home'" />
	<Navbar />
	<router-view v-slot="{ Component, route }">
		<transition name="fade" mode="out-in">
			<component :is="Component" :key="route.path" />
		</transition>
	</router-view>
</template>

<script>
import MainImage from './components/MainImage.vue';
export default {
	name: 'App',
	components: {
		MainImage,
	},
};
</script>

<style lang="scss">
body {
	&.hiddenscroll {
		overflow: hidden;
	}
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
